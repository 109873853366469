import React from 'react';

function Results() {
  return (
    <div className="Results">
      <h1>Results Page</h1>
    </div>
  );
}

export default Results;
