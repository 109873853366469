import React from 'react';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';
import Container from '../Container';

function Header() {
  return (
    <div className={styles.Header}>
      <Container>
        <h1><Link to="/">Image cutter</Link></h1>
      </Container>
    </div>
  );
}

export default Header;
