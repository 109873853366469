import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Results from './Pages/Results';
import Page404 from './Pages/404';

function Main() {
  return (
    <div className="Main">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/results" component={Results} />
        <Route path="*" component={Page404} />
      </Switch>
    </div>
  );
}

export default Main;
