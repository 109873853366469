import React from 'react';

function Page404() {
  return (
    <div className="Page404">
      <h1>404</h1>
    </div>
  );
}

export default Page404;
