import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

function Container(props) {
  const { children } = props;

  return (
    <div className={styles.Container}>
      {children}
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.object.isRequired,
};


export default Container;
