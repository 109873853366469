import React from 'react';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';
import Container from '../Container';

function Footer() {
  return (
    <div className={styles.Footer}>
      <Container>
        <h1>
          <Link to="/">IMAGE CUTTER</Link>
          <br />
          test task 2020
        </h1>
      </Container>
    </div>
  );
}

export default Footer;
