import React from 'react';
import styles from './style.module.scss';
import Cutter from '../../Components/Cutter';

function Home() {
  return (
    <div className={styles.Home}>
        <Cutter />
    </div>
  );
}

export default Home;
