import React from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Range } from 'react-range';
import styles from './style.module.scss';
import cropImage from './crop.svg';

class Cutter extends React.Component {
  constructor(props) {
    super(props);
    this.cutterRef = React.createRef();
    this.state = {
      image: null,
      imageLink: null,
      result: null,
      values: [2],
      maxValue: 3,
      errorMessage: '⠀',
      validSizes: false,
    };
  }


  componentDidMount() {
    this.cutterRef.current.addEventListener('dragover', (e) => {
      e.preventDefault();
    });
    this.cutterRef.current.addEventListener('drop', (e) => {
      e.preventDefault();

      const dt = e.dataTransfer;
      const { files } = dt;

      if (files[0].type === '' || files[0].type !== 'image/png' || files[0].type !== 'image/jpg' || files[0].type !== 'image/jpeg') {
        this.setState({
          errorMessage: 'Error: use only .png .jpg or .jpeg images.',
        });
      }

      let dataURL = null;
      // eslint-disable-next-line no-undef
      const img = new Image();
      // eslint-disable-next-line no-undef
      const reader = new FileReader();
      reader.onload = function () {
        dataURL = reader.result;
        img.src = dataURL;
      };
      reader.readAsDataURL(files[0]);

      img.onload = () => {
        if (img.height > 300 && img.width > 300) {
          this.setState({
            validSizes: true,
            imageLink: dataURL,
          }, () => {
            if (this.validate(files[0])) {
              this.setState({
                image: files[0],
              });
            }
          });
        }
        if (this.validate(files[0])) {
          this.setState({
            image: files[0],
          });
        }
      };
    });
  }

  validate = (file) => {
    const { validSizes } = this.state;
    let validStatuses = {
      extension: false,
      size: false,
      sizeInner: false,
    };
    // eslint-disable-next-line no-undef

    const extensionName = file.type;
    if (extensionName === 'image/png' || extensionName === 'image/jpg' || extensionName === 'image/jpeg') {
      validStatuses = {
        ...validStatuses,
        extension: true,
      };
    }

    if (file.size > 5 * 1024 * 1024) {
      this.setState({
        errorMessage: 'Error: the photo should be < than 5MB',
      });
      return false;
    }
    validStatuses = {
      ...validStatuses,
      size: true,
    };

    if (!validSizes) {
      this.setState({
        errorMessage: 'Error: the photo should be > 300x300 px',
      });
      return false;
    }

    if (validStatuses.extension && validStatuses.size && validSizes) {
      return true;
    }

    this.setState({
      errorMessage: 'Error: use only .png .jpg or .jpeg images.',
    });
    return false;
  };

  onLoad = (e) => {
    if (e.target.files[0].type === '' || e.target.files[0].type !== 'image/png' || e.target.files[0].type !== 'image/jpg' || e.target.files[0].type !== 'image/jpeg') {
      this.setState({
        errorMessage: 'Error: use only .png .jpg or .jpeg images.',
      });
    }

    let dataURL = null;
    const files = e.target.files[0];

    // eslint-disable-next-line no-undef
    const img = new Image();
    img.alt = "Crop";
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.onload = function () {
      dataURL = reader.result;
      img.src = dataURL;

    };
    reader.readAsDataURL(files);

    img.onload = () => {
      if (img.height > 300 && img.width > 300) {
        this.setState({
          validSizes: true,
          imageLink: dataURL,
        }, () => {
          if (this.validate(files)) {
            this.setState({
              image: files,
            });
          }
        });
      }
      if (this.validate(files)) {
        this.setState({
          image: files,
        });
      }
    };
  };


  // eslint-disable-next-line no-return-assign
  setEditorRef = (editor) => this.editor = editor;

  resetCrop = () => {
    this.setState({
      image: null,
      imageLink: null,
      result: null,
      values: [2],
      maxValue: 3,
      errorMessage: '⠀',
      validSizes: false,
    });
  };

  sizeHandler = (sizeType) => () => {
    const { maxValue, values } = this.state;
    if (sizeType === 'plus') {
      this.setState({
        values: [maxValue > values[0] ? values[0] + 0.1
          : values[0]],
      });
      return;
    }
    this.setState({
      values: [values[0] > 1 ? values[0] - 0.1 : values[0]],
    });
  };

  saveCrop = () => {
    const canvas = this.editor.getImageScaledToCanvas();
    canvas.toBlob((blob) => {
      // после того, как Blob создан, загружаем его

      this.setState({
        result: URL.createObjectURL(blob),
      });
    }, 'image/png');
  };

  render() {
    const {
      image, values, errorMessage, result,
    } = this.state;
    return (
      <div ref={this.cutterRef} style={result ? { height: '100%', marginTop: 80, marginBottom: 110 } : null} className={styles.Cutter}>
        {image && !result
        && (
        <div className={styles.cutterPlay}>
          <AvatarEditor
            ref={this.setEditorRef}
            image={image}
            width={300}
            height={300}
            border={0}
            color={[255, 255, 255, 1]}
            scale={values[0]}
            disableHiDPIScaling
            rotate={0}
          />
          <div className={styles.rangeWrapper}>
            <div className={styles.scopeMinus} onClick={this.sizeHandler('minus')}>—</div>
            <Range
              step={0.1}
              min={1}
              max={3}
              values={values}
              onChange={(value) => this.setState({ values: value })}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '6px',
                    width: '100%',
                    backgroundColor: '#ccc',
                    borderRadius: '5px',
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '20px',
                    width: '20px',
                    backgroundColor: '#263A4C',
                    borderRadius: '50%',
                    outline: 'none',
                  }}
                />
              )}
            />
            <div className={styles.scopePlus} onClick={this.sizeHandler('plus')}>+</div>
          </div>
          <div className={styles.buttons}>
            <button type="button" className={styles.resetButton} onClick={this.resetCrop}>change</button>
            <button type="button" className={styles.saveButton} onClick={this.saveCrop}>apply</button>
          </div>
        </div>
        )}

        {!image && !result && (
        <div className={styles.inputfileCustom}>
          <input type="file" className={styles.inputfile} id="file" onChange={this.onLoad} />
          <img src={cropImage} alt="Crop" />
          <h2>Drag photo here or</h2>
          <label htmlFor="file">
            click for upload
          </label>
          <p className={styles.errorMessage}>{errorMessage}</p>
        </div>
        )}
        {result
          && (
          <div className={styles.results}>
            <button type="button" className={styles.resetButtonResult} onClick={this.resetCrop}>Reset</button>


            <h2>Result</h2>
            <img alt="Crop" src={result} />

            <h2>Blob</h2>
            <p>{result}</p>

          </div>
          )}

      </div>
    );
  }
}
export default Cutter;
